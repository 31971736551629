.translation-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 20px;
  .key-name {
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%);
    font-size: 14px;
    color: #070707;
    padding: 10px 15px;
    margin-bottom: 15px;
  }
  .no-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .no-content-header {
      font-size: 25px;
      font-weight: 500;
      text-align: center;
    }
    .no-content-span {
      text-align: center;
    }
  }
}
.action-buttons {
  display: flex;
  align-items: center;
  .edit-btn {
    font-weight: bold;
    color: #009688;
    cursor: pointer;
  }
  .revisions-btn {
    font-weight: bold;
    color: #303f9f;
    cursor: pointer;
  }
  .vertial-bar {
    height: 20px;
    border-right: 1px solid#ccc;
    padding-left: 10px;
    margin-right: 10px;
  }
}
