/*
 * Global Body Styles
 * - Resets default margins.
 * - Sets a common font family with fallbacks for cross-platform consistency.
 * - Applies font smoothing for better text rendering.
 * - Sets a light background color.
 */
body {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #495057;
  background-color: #f7f7f7;
}

/*
 * Code Styling
 * - Uses a monospace font for better readability of code blocks.
 */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
 * Overrides Bootstrap Card Styling
 * - Adds a subtle shadow to give cards visual depth.
 * - Margin is reset to prevent unexpected layout issues.
 */
.card {
  box-shadow: 0 0 5px 1px #069ce6;
  margin: 0;
}

/*
 * Hover Effect for Cards
 * - Increases the shadow slightly on hover for interaction feedback.
 */
.card:hover {
  box-shadow: 0 0 5px 1px #485ec4;
}

/*
 * Main Content Padding
 * - Ensures the main content does not have any default padding.
 */
.main-content {
  padding: 0;
}

.page-content {
  height: 100%;
  padding-bottom: 20px;
}

/* Redefines the style of rc-dock and ag-grid elements */
.dock-panel {
  min-width: 150px !important;
}
.dock-panel.dock-style-headless {
  background: transparent;
}
.dock-tab-btn {
  padding-left: unset !important;
}
.ag-header-row.ag-header-row-column {
  height: 30px;
}
.my-panel-extra-btn {
  cursor: pointer;
  color: red;
  display: inline-block;
  width: 18px;
  height: 24px;
  text-align: center;
  opacity: 0.5;
  transition: all 0.25s ease-in-out;
  line-height: 30px;
  &:hover {
    opacity: 1;
  }
}

/* Progress screen of database syncing*/
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Add the blur effect */
  z-index: 1000; /* Ensure it's on top */
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-container {
  background-color: white;
  padding: 60px 80px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  font-size: 18px;
}
.progress {
  height: 12.5px !important;
  background-color: #f6f6f6 !important;
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
