.modal-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  height: 100%;
  .input-key {
    width: 100%;
    height: 100% !important;
    min-height: 100px;
    font-size: 14px;
    padding: 5px 10px;
  }
  .revisions-list {
    width: 100%;
    height: 100% !important;
  }
  .revision-text {
    font-size: 14px;
  }
}
