.list-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  background-color: #eff4f9;
}

.no-keys {
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eff4f9;
}
.no-keys > span {
  font-size: 15px;
}
