.tooltip-text {
  font-size: 12px;
  text-align: left;
  color: black;
}

#tooltip .tooltip-inner {
  text-align: left;
  background-color: #d1e4e6;
  opacity: 2;
}

.key-list-item {
  cursor: pointer;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: justify;
}

.key-list-item.active {
  background-color: #b3d2d4;
}

.key-list-item:hover:not(.active) {
  background-color: #d1e4e6;
}

.item-text {
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3em;
  text-align: left;
}

span.item-text::first-letter {
  text-transform: uppercase;
}
