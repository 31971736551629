.page-title {
  color: #495057;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 15px;
}
.search-key {
  position: relative;
  span {
    position: absolute;
    top: 7px;
    left: 10px;
    color: #009688;
    font-size: 21px;
    z-index: 100;
  }
  input {
    height: 30px;
    width: 100%;
  }
}
.filter-container {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  .filter-select {
    margin-right: 15px;
  }
}
